import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
function Statuses() {
  const { user } = useContext(AuthContext);

  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [status, setStatus] = useState('');
  const [duration, setDuration] = useState('');
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateData, setDateData] = useState({
    startDate: dayjs(), // Initialize with the current date
    endDate: dayjs(), // Initialize with the current date
  });
  console.log('dateData', dateData);
  const handleDateChange = (field) => (newValue) => {
    const updatedDate = dayjs(newValue);

    setDateData((prevState) => {
      if (field === 'startDate' && updatedDate.isAfter(prevState.endDate, 'day')) {
        toast.error('Start Date cannot be after End Date');
        return prevState;
      }

      if (field === 'endDate' && updatedDate.isBefore(prevState.startDate, 'day')) {
        toast.error('End Date cannot be before Start Date');
        return prevState;
      }

      return {
        ...prevState,
        [field]: updatedDate,
      };
    });
  };

  // Fetch Departments
  const getDepartments = async () => {
    try {
      const response = await apiService.get('/api/v1/departments/list');
      setDepartments(response?.data?.departments || []);
    } catch (error) {
      console.error('Error fetching departments:', error);
      toast.error('Failed to fetch departments');
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  // Fetch Attendance Data
  const handleGetData = async () => {
    setLoading(true);
    try {
      const dataToSend = {
        // timeRange: duration,
        startDate: dayjs(dateData.startDate).format(),
        endDate: dayjs(dateData.endDate).format(),
        status,
      };

      if (departmentId) {
        dataToSend.departmentId = departmentId;
      }

      const response = await apiService.post('/api/v1/attendance/fetchUserByStatus', dataToSend);
      if (response.data.success) {
        setAttendanceLogs(response.data.data || []);
      } else {
        setAttendanceLogs([]);
      }
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
      toast.error(error.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  // Form Validation
  const isFormValid = status && dateData.startDate && dateData.endDate;

  return (
    // <DashboardLayout>
    // <DashboardNavbar />
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h6" color="white">
                Attendance Status
              </MDTypography>
            </MDBox>
            <MDBox pt={3} px={3} my={3}>
              <Grid container spacing={3}>
                {/* Status Dropdown */}
                <Grid item xs={12} md={2}>
                  <Typography variant="h6">Status</Typography>
                  <FormControl fullWidth>
                    <Select
                      aria-label="Status"
                      sx={{ padding: '10px' }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <MenuItem value="present">Present</MenuItem>
                      <MenuItem value="absent">Absent</MenuItem>
                      <MenuItem value="late">Late</MenuItem>
                      <MenuItem value="earlyCheckout">Early Checkout</MenuItem>
                      <MenuItem value="lateEarlyCheckout">Late & Early Checkout</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Duration Dropdown
                <Grid item xs={12} md={3}>
                  <Typography variant="h6">Duration</Typography>
                  <FormControl fullWidth>
                    <Select
                      aria-label="Duration"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      sx={{ padding: '10px' }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                    >
                      <MenuItem value="1day">1 Day</MenuItem>
                      <MenuItem value="1week">1 Week</MenuItem>
                      <MenuItem value="1month">1 Month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} md={2} my={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={dateData.startDate}
                      maxDate={dayjs()}
                      onChange={handleDateChange('startDate')}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={2} my={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      value={dateData.endDate}
                      maxDate={dayjs()}
                      onChange={handleDateChange('endDate')}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Department Dropdown */}
                {/* <Grid item xs={12} md={3}>
                  <Typography variant="h6">Department</Typography>
                  <FormControl fullWidth>
                    <Select
                      aria-label="Department"
                      value={departmentId}
                      onChange={(e) => setDepartmentId(e.target.value)}
                      sx={{ padding: '10px' }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                    >
                      {departments.map((department) => (
                        <MenuItem key={department._id} value={department._id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} md={3} my={3}>
                  <Autocomplete
                    options={departments}
                    getOptionLabel={(option) => option.name} // Display department name
                    onChange={(event, newValue) => setDepartmentId(newValue?._id || '')} // Update departmentId
                    renderInput={(params) => (
                      <TextField {...params} label="Select Department" variant="outlined" />
                    )}
                  />
                </Grid>

                {/* Get Attendance Button */}
                <Grid item xs={12} md={3} my={3}>
                  <Button
                    disabled={!isFormValid || loading}
                    onClick={handleGetData}
                    fullWidth
                    style={{
                      backgroundColor: '#0e76ff',
                      color: 'white',
                    }}
                  >
                    {loading ? <CircularProgress color="inherit" size={25} /> : 'Search'}
                  </Button>
                </Grid>
              </Grid>

              {/* Attendance Logs */}
              {attendanceLogs && attendanceLogs.length > 0 ? (
                <MDBox mt={3}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead
                        sx={{
                          display: 'contents',
                          width: '100%',
                        }}
                      >
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Person Number</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attendanceLogs.map((log, index) => (
                          <TableRow key={index}>
                            <TableCell>{log.name}</TableCell>
                            <TableCell>{log.email}</TableCell>
                            <TableCell>{log.personNumber}</TableCell>
                            <TableCell>{dayjs(log.date).format('YYYY-MM-DD')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <MDTypography variant="h6" color="secondary" align="center" mt={3}>
                  No Record Found
                </MDTypography>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
    // </DashboardLayout>
  );
}

export default Statuses;
