import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/background.jpg';
import apiService from '../../../services/ApiService';
import { AuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [credentials, setCredentials] = useState({ password: '', confirmPassword: '' });
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dataToSend = {
      currentPassword: credentials.password,
      confirmPassword: credentials.confirmPassword,

      email: location?.state?.email || '',
    };
    setIsLoading(true);
    try {
      const response = await apiService.post('/api/v1/password/resetPassword', dataToSend);

      console.log('response', response);
      if (response?.status === 200) {
        toast.success(response.data.message);
        navigate('/authentication/sign-in');
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Invalid Data';
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Confirm Password"
                name="confirmPassword"
                value={credentials.confirmPassword}
                onChange={handleChange}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={isLoading}
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
              >
                {isLoading ? <CircularProgress color="inherit" /> : 'Reset Password'}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
