// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard';
import Companies from 'layouts/companies';
import BusinessUnits from 'layouts/business';
import Departments from 'layouts/departments';
import Profile from 'layouts/profile';
import FaceUpdate from 'layouts/faceUpdate'
import Users from 'layouts/users';
import Roles from 'layouts/roles';
import Pendings from 'layouts/pendings';
import Attendances from 'layouts/attendances';
import Declined from 'layouts/declined';
import MarkAttendance from 'layouts/markAttendance';
import AttendanceReport from 'layouts/userReport';

// @mui icons
import Icon from '@mui/material/Icon';
import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';
import Shifts from 'layouts/shift';
import UserAttendanceLogs from 'layouts/userAttendanceLogs';
import AdminAttendance from 'layouts/adminAttendance';
import ImportedUsers from 'layouts/importedUsers';
import PresentAttendance from 'layouts/presentAttendance';
import AbsentAttendance from 'layouts/absentAttendance';
import LateAttendance from 'layouts/lateAttendance';
import EarlyCheckout from 'layouts/earlyCheckoutAttendance';
import LateEarlyAttendance from 'layouts/lateEarlyAttendance';
import Statuses from 'layouts/statuses';

// const UseRoutes = () => {
//   const { permission } = useContext(AuthContext);
//   const {
//     Admin = false,
//     HrManager = false,
//     HRBP = false,
//     ItAdmin = false,
//     Employee = false,
//   } = permission;

//   console.log('permission', permission);

//   // Define all routes and their required permissions
//   const routes = [
//     {
//       type: 'collapse',
//       name: 'Dashboard',
//       key: 'dashboard',
//       icon: <Icon fontSize="small">dashboard</Icon>,
//       route: '/dashboard',
//       component: <Dashboard />,
//       permission: Admin || HrManager || Employee,
//     },
//     {
//       type: 'collapse',
//       name: 'Companies',
//       key: 'companies',
//       icon: <Icon fontSize="small">business</Icon>,
//       route: '/companies',
//       component: <Companies />,
//       permission: Admin,
//     },
//     {
//       type: 'collapse',
//       name: 'Business Units',
//       key: 'business-units',
//       icon: <Icon fontSize="small">apartment</Icon>,
//       route: '/business-units',
//       component: <BusinessUnits />,
//       permission: Admin,
//     },
//     {
//       type: 'collapse',
//       name: 'Departments',
//       key: 'departments',
//       icon: <Icon fontSize="small">account_tree</Icon>,
//       route: '/departments',
//       component: <Departments />,
//       permission: Admin || HrManager,
//     },
//     {
//       type: 'collapse',
//       name: 'Users',
//       key: 'users',
//       icon: <Icon fontSize="small">people</Icon>,
//       route: '/users',
//       component: <Users />,
//       permission: Admin || HrManager,
//     },
//     {
//       type: 'collapse',
//       name: 'Imported Users',
//       key: 'imported-users',
//       icon: <Icon fontSize="small">people</Icon>,
//       route: '/imported-users',
//       component: <ImportedUsers />,
//       permission: Admin,
//     },
//     {
//       type: 'collapse',
//       name: 'Pending Users',
//       key: 'pendings',
//       icon: <Icon fontSize="small">people</Icon>,
//       route: '/pendings',
//       component: <Pendings />,
//       permission: HRBP,
//     },
//     {
//       type: 'collapse',
//       name: 'Blocked Users',
//       key: 'declined',
//       icon: <Icon fontSize="small">block</Icon>,
//       route: '/declined',
//       component: <Declined />,
//       permission: Admin,
//     },
//     // {
//     //   type: 'collapse',
//     //   name: 'Attendance Logs',
//     //   key: 'attendances',
//     //   icon: <Icon fontSize="small">schedule</Icon>,
//     //   route: '/attendances',
//     //   component: <Attendances />,
//     //   permission: Admin,
//     // },
//     // {
//     //   type: 'collapse',
//     //   name: 'Mark Attendance',
//     //   key: 'markAttendances',
//     //   icon: <Icon fontSize="small">schedule</Icon>,
//     //   route: '/markAttendances',
//     //   component: <MarkAttendance />,
//     //   permission: Employee,
//     // },
//     {
//       type: 'collapse',
//       name: 'Mark Attendance',
//       key: 'markAttendances',
//       icon: <Icon fontSize="small">schedule</Icon>,
//       route: '/markAttendances',
//       component: <MarkAttendance />,
//       permission: Employee && !Admin && !HrManager && !HRBP && !ItAdmin,
//     },

//     // {
//     //   type: 'collapse',
//     //   name: 'Attendance Report',
//     //   key: 'attendance-report',
//     //   icon: <Icon fontSize="small">schedule</Icon>,
//     //   route: '/attendance-report',
//     //   component: <AttendanceReport />,
//     //   permission: Employee,
//     // },
//     {
//       type: 'collapse',
//       name: 'Attendance Logs',
//       key: 'adminAttendances-report',
//       icon: <Icon fontSize="small">schedule</Icon>,
//       route: '/adminAttendances-report',
//       component: <UserAttendanceLogs />,
//       permission: Admin,
//     },

//     {
//       type: 'collapse',
//       name: 'Attendance Reports',
//       key: 'report-attedance',
//       icon: <Icon fontSize="small">schedule</Icon>,
//       route: '/report-attedance',
//       component: <AdminAttendance />,
//       permission: Admin || HrManager,
//     },
//     {
//       type: 'collapse',
//       name: 'Roles',
//       key: 'roles',
//       icon: <Icon fontSize="small">supervised_user_circle</Icon>,
//       route: '/roles',
//       component: <Roles />,
//       permission: Admin,
//     },
//     {
//       type: 'collapse',
//       name: 'Profile',
//       key: 'profile',
//       icon: <Icon fontSize="small">person</Icon>,
//       route: '/profile',
//       component: <Profile />,
//       permission: Admin || HrManager || Employee,
//     },
//     {
//       type: 'collapse',
//       name: 'Shifts',
//       key: 'shifts',
//       icon: <Icon fontSize="small">apartment</Icon>,
//       route: '/shifts',
//       component: <Shifts />,
//       permission: Admin,
//     },
//   ];

// // Reorder routes to bring Profile at the top if the user is an HR Manager
// if (HrManager) {
//   // Create a new array with Profile at the top
//   const profileRoute = routes.find(route => route.key === 'profile');
//   const filteredRoutes = routes.filter(route => route.key !== 'profile');
//   routes = [profileRoute, ...filteredRoutes];
// }

//   // Filter routes based on the user’s permissions
//   const allowedRoutes = routes.filter((route) => route.permission);

//   return allowedRoutes;
// };

// export default UseRoutes;

const UseRoutes = () => {
  const { permission } = useContext(AuthContext);
  const {
    Admin = false,
    HrManager = false,
    HRBP = false,
    ItAdmin = false,
    Employee = false,
  } = permission;

  console.log('permission', permission);

  // Define all routes and their required permissions
  let routes = [
    {
      type: 'collapse',
      name: 'Dashboard',
      key: 'dashboard',
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: '/dashboard',
      component: <Dashboard />,
      permission: Admin || HrManager || Employee,
    },
    {
      type: 'collapse',
      name: 'Companies',
      key: 'companies',
      icon: <Icon fontSize="small">business</Icon>,
      route: '/companies',
      component: <Companies />,
      permission: Admin,
    },
    {
      type: 'collapse',
      name: 'Business Units',
      key: 'business-units',
      icon: <Icon fontSize="small">apartment</Icon>,
      route: '/business-units',
      component: <BusinessUnits />,
      permission: Admin,
    },
    {
      type: 'collapse',
      name: 'Departments',
      key: 'departments',
      icon: <Icon fontSize="small">account_tree</Icon>,
      route: '/departments',
      component: <Departments />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Users',
      key: 'users',
      icon: <Icon fontSize="small">people</Icon>,
      route: '/users',
      component: <Users />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Imported Users',
      key: 'imported-users',
      icon: <Icon fontSize="small">people</Icon>,
      route: '/imported-users',
      component: <ImportedUsers />,
      permission: Admin,
    },
    {
      type: 'collapse',
      name: 'Pending Users',
      key: 'pendings',
      icon: <Icon fontSize="small">people</Icon>,
      route: '/pendings',
      component: <Pendings />,
      permission: HRBP,
    },
    {
      type: 'collapse',
      name: 'Blocked Users',
      key: 'declined',
      icon: <Icon fontSize="small">block</Icon>,
      route: '/declined',
      component: <Declined />,
      permission: Admin,
    },
    {
      type: 'collapse',
      name: 'Mark Attendance',
      key: 'markAttendances',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/markAttendances',
      component: <MarkAttendance />,
      permission: Employee || (HrManager && !Admin && !HRBP && !ItAdmin),
    },
    {
      type: 'collapse',
      name: 'Update Face',
      key: 'updateface',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/updateface',
      component: <FaceUpdate />,
      permission: Employee || (HrManager && !Admin && !HRBP && !ItAdmin),
    },
    {
      type: 'collapse',
      name: 'Attendance Reports',
      key: 'report-attedance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/report-attedance',
      component: <AdminAttendance />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Attendance Logs',
      key: 'adminAttendances-report',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/adminAttendances-report',
      component: <UserAttendanceLogs />,
      permission: Admin || HrManager,
    },

    // {
    //   type: 'collapse',
    //   name: 'Statuses',
    //   key: 'statuses',
    //   icon: <Icon fontSize="small">schedule</Icon>,
    //   route: '/statuses',
    //   component: <Statuses />,
    //   permission: Admin || HrManager,
    // },

    {
      type: 'collapse',
      name: 'Present Attendance',
      key: 'present-attendance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/present-attendance',
      component: <PresentAttendance />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Absent Attendance',
      key: 'absent-attendance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/absent-attendance',
      component: <AbsentAttendance />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Late Attendance',
      key: 'late-attendance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/late-attendance',
      component: <LateAttendance />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Early Checkout ',
      key: 'earlycheckout-attendance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/earlycheckout-attendance',
      component: <EarlyCheckout />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Late  Early Checkout',
      key: 'lateearly-attendance',
      icon: <Icon fontSize="small">schedule</Icon>,
      route: '/lateearly-attendance',
      component: <LateEarlyAttendance />,
      permission: Admin || HrManager,
    },
    {
      type: 'collapse',
      name: 'Roles',
      key: 'roles',
      icon: <Icon fontSize="small">supervised_user_circle</Icon>,
      route: '/roles',
      component: <Roles />,
      permission: Admin,
    },
    {
      type: 'collapse',
      name: 'Profile',
      key: 'profile',
      icon: <Icon fontSize="small">person</Icon>,
      route: '/profile',
      component: <Profile />,
      permission: Admin || HrManager || Employee,
    },
    {
      type: 'collapse',
      name: 'Shifts',
      key: 'shifts',
      icon: <Icon fontSize="small">apartment</Icon>,
      route: '/shifts',
      component: <Shifts />,
      permission: Admin,
    },
  ];

  if (HrManager) {
    const profileRoute = routes.find((route) => route.key === 'profile');
    const filteredRoutes = routes.filter((route) => route.key !== 'profile');
    routes = [profileRoute, ...filteredRoutes];
  }

  const allowedRoutes = routes.filter((route) => route.permission);

  return allowedRoutes;
};

export default UseRoutes;
