import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import ProfilesList from 'examples/Lists/ProfilesList';
import Header from 'layouts/profile/components/Header';
import apiService from '../../services/ApiService';
import { AuthContext } from '../../context/AuthContext';
import { Business } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { Button, Card, CircularProgress } from '@mui/material';
import NewProfileCard from 'examples/Cards/InfoCards/NewProfileCard';
import FaceWeb from 'components/FaceWeb';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function Overview() {
  // const [profiles, setProfiles] = useState([]);
  const { user, permission } = useContext(AuthContext);
  console.log('user', user);

  const navigate = useNavigate();

  const { Admin = false, HrManager = false, HRBP = false } = permission;
  const [showFaceDetection, setShowFaceDetection] = useState(false);
  const [userData, setUserData] = useState(null);
  const [faceImage, setFaceImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleRegisterClick = () => {
    setShowFaceDetection(true);
  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append('fullName', userData.fullName); // Full name
    formData.append('personNumber', userData.personNumber); // Person number
    // formData.append('roles', JSON.stringify(user.roles)); // Roles (converted to JSON string)
    formData.append('isApproved', userData.isApproved); // Approval status
    formData.append('declinedStatus', userData.declinedStatus); // Declined status
    formData.append('password', userData.password); // Password, if updating
    if (faceImage) {
      const file = dataURLtoFile(faceImage, 'capturedFace.jpg');
      formData.append('faceImage', file);
    }

    setIsLoading(true);
    try {
      const response = await apiService.put(`/api/v1/users/update/${userData?._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure correct content type
        },
      });


      if (response?.data?.success) {
        toast.success(response.data.message);
        navigate('/dashboard');
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ;
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    try {
      const response = await apiService.get(`/api/v1/users/detail/${user?._id}`);
      if (response?.data?.success) {
        // setUser(response.data.data);
        setUserData(response?.data?.data?.user);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong';
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h6" color="white">
                    Update Face
                  </MDTypography>
                </MDBox>
                <MDBox pt={5} pb={3} px={5}>
                  <Grid container spacing={4}>
                    {/* Profile Image */}

                    <Grid item xs={12} md={8} lg={12}>
                      {userData?.allowFaceUpdate ? (
                        <>
                          <MDBox mb={2}>
                            {!showFaceDetection && (
                              <Button
                                onClick={handleRegisterClick}
                                style={{
                                  backgroundColor: '#0e76ff',
                                  color: 'white',
                                }}
                              >
                                Capture Face Image
                              </Button>
                            )}
                            {showFaceDetection && (
                              <FaceWeb
                                setFaceImage={setFaceImage}
                                isShown={isShown}
                                setIsShown={setIsShown}
                                isReset={true}
                              />
                            )}
                          </MDBox>

                          <MDBox mt={4}>
                            {isShown && (
                              <Button
                                onClick={handleUpdateProfile}
                                style={{
                                  backgroundColor: '#0e76ff',
                                  color: 'white',
                                }}
                              >
                                {isLoading ? <CircularProgress color="inherit" /> : 'Update Face'}
                              </Button>
                            )}
                          </MDBox>
                        </>
                      ) : (
                        <MDBox py={3}>
                          <h1 style={{ textAlign: 'center', color: '#1A73E8', fontWeight: 'bold' }}>
                            Cannot Update Face
                          </h1>
                          <p style={{ textAlign: 'center', color: '#888' }}>
                            You are not allowed to update the face until the admin allows it.
                          </p>
                        </MDBox>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </>
    </DashboardLayout>
  );
}

export default Overview;
