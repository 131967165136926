import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
    backgroundColor: '#42424a',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData('Frozen yoghurt', 159)];

export default function StatusRecord({ attendanceLogs }) {
  console.log('attendanceLogs', attendanceLogs);
  return (
    <Box
      sx={{
        mt: 10,
      }}
    >
      <TableContainer
        sx={{
          border: '1px solid #42424a',
          padding: '10px',
        }}
        component={Paper}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2,
          }}
        >
          {/* Add any buttons here */}
        </Box>

        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead
            sx={{
              display: 'contents',
              width: '100%',
            }}
          >
            <TableRow>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">User Name</StyledTableCell>
              <StyledTableCell align="center">Person Number</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceLogs?.length > 0 ? (
              attendanceLogs.map((row, index) => (
                <StyledTableRow key={`${index}-${row.date}`}>
                  {/* Date */}
                  <StyledTableCell align="center">
                    {dayjs(row.date).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  {/* User Details */}
                  <StyledTableCell align="center">{row?.name || 'N/A'}</StyledTableCell>
                  <StyledTableCell align="center">{row?.personNumber || 'N/A'}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={3}>
                  No attendance logs available
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
