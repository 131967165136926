// import React, { useRef, useEffect, useState } from 'react';
// import Webcam from 'react-webcam';
// import * as faceapi from 'face-api.js';

// export default function FaceWeb({ setFaceImage, setIsShown, isShown }) {
//   const webcamRef = useRef(null);
//   const [modelsLoaded, setModelsLoaded] = useState(false);
//   const [blinkCount, setBlinkCount] = useState(0);
//   const [blinkDetected, setBlinkDetected] = useState(false);
//   const [faceDetected, setFaceDetected] = useState(false);
//   const [isButtonEnabled, setIsButtonEnabled] = useState(false);
//   const [showFaceDetection, setShowFaceDetection] = useState(true); // Always show face detection
//   const [videoReady, setVideoReady] = useState(false);
//   const [capturedImage, setCapturedImage] = useState(null);
//   const [hasBlinkedOnce, setHasBlinkedOnce] = useState(false); // Track if blink is detected once

//   useEffect(() => {
//     const loadModels = async () => {
//       try {
//         const MODEL_URL = '/models';
//         await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
//         await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
//         setModelsLoaded(true);
//         console.log('Models loaded successfully!');
//       } catch (error) {
//         console.error('Error loading models:', error);
//       }
//     };

//     loadModels();
//   }, []);

//   const calculateEAR = (eye) => {
//     const [p1, p2, p3, p4, p5, p6] = eye;

//     const vertical1 = Math.sqrt(Math.pow(p2.x - p6.x, 2) + Math.pow(p2.y - p6.y, 2));
//     const vertical2 = Math.sqrt(Math.pow(p3.x - p5.x, 2) + Math.pow(p3.y - p5.y, 2));
//     const horizontal = Math.sqrt(Math.pow(p1.x - p4.x, 2) + Math.pow(p1.y - p4.y, 2));

//     return (vertical1 + vertical2) / (2 * horizontal);
//   };

//   const detectFaceAndBlink = async () => {
//     if (webcamRef.current && modelsLoaded && videoReady && !blinkDetected) {
//       const video = webcamRef.current.video;

//       const detections = await faceapi
//         .detectSingleFace(
//           video,
//           new faceapi.TinyFaceDetectorOptions({ inputSize: 224, scoreThreshold: 0.4 })
//         )
//         .withFaceLandmarks();

//       if (!detections) {
//         console.log('No face detected.');
//         setFaceDetected(false);
//         setIsButtonEnabled(false);
//         return;
//       }

//       setFaceDetected(true);
//       // setIsButtonEnabled(true);

//       const landmarks = detections.landmarks;
//       const leftEye = landmarks.getLeftEye();
//       const rightEye = landmarks.getRightEye();

//       if (!leftEye || !rightEye) {
//         console.error('Eye landmarks not detected.');
//         return;
//       }

//       const leftEAR = calculateEAR(leftEye);
//       const rightEAR = calculateEAR(rightEye);
//       const avgEAR = (leftEAR + rightEAR) / 2;

//       const blinkThreshold = 0.25;
//       if (avgEAR < blinkThreshold) {
//         setBlinkDetected(true); // Blink detected, set the state to true
//         console.log('Blink detected, stopping further detection.');
//       }
//     }
//   };

//   useEffect(() => {
//     let animationFrameId;

//     const detectWithAnimation = async () => {
//       if (!blinkDetected) {
//         // Stop detecting after the first blink
//         await detectFaceAndBlink();
//         animationFrameId = requestAnimationFrame(detectWithAnimation);
//       }
//     };

//     if (modelsLoaded && videoReady) {
//       detectWithAnimation();
//     }

//     return () => cancelAnimationFrame(animationFrameId);
//   }, [modelsLoaded, videoReady, blinkDetected]);

//   useEffect(() => {
//     setIsButtonEnabled(faceDetected && blinkDetected);
//   }, [faceDetected, blinkDetected]);

//   const handleCapture = () => {
//     if (!isButtonEnabled) {
//       alert('Capture failed. Please ensure a face is detected and try again.');
//       return;
//     }

//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setFaceImage(imageSrc);
//       setIsShown(true);
//       setCapturedImage(imageSrc);
//     }
//   };

//   return (
//     <div>
//       {!isShown ? (
//         <div>
//           <Webcam
//             ref={webcamRef}
//             videoConstraints={{ width: 640, height: 480, facingMode: 'user' }}
//             onLoadedData={() => setVideoReady(true)}
//             onUserMedia={() => console.log('Webcam is ready!')}
//             onUserMediaError={(error) => alert(`Webcam access denied: ${error.message}`)}
//             screenshotFormat="image/jpeg"
//           />
//           {modelsLoaded ? (
//             <div>
//               {faceDetected ? (
//                 <p style={{ color: 'green', fontWeight: 'bold' }}>Face Detected!</p>
//               ) : (
//                 <p style={{ color: 'red' }}>No Face Detected</p>
//               )}
//               {!blinkDetected ? (
//                 <p style={{ color: 'blue', fontWeight: 'bold' }}>Please Blink at least once</p>
//               ) : null}
//               {blinkDetected && (
//                 <p style={{ color: 'green', fontWeight: 'bold' }}>Blink Detected!</p>
//               )}
//             </div>
//           ) : (
//             <p>Loading models...</p>
//           )}
//           <button
//             onClick={handleCapture}
//             disabled={!isButtonEnabled}
//             style={{
//               marginTop: '20px',
//               padding: '10px 20px',
//               fontSize: '16px',
//               backgroundColor: isButtonEnabled ? 'blue' : 'gray',
//               color: 'white',
//               cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
//               border: 'none',
//               borderRadius: '5px',
//             }}
//           >
//             {isButtonEnabled ? 'Capture Image' : 'Waiting for Face and Blink...'}
//           </button>
//         </div>
//       ) : (
//         <img
//           src={capturedImage}
//           alt="Captured Face"
//           style={{
//             maxWidth: '100%',
//             height: 'auto',
//             marginTop: '20px',
//             borderRadius: '5px',
//           }}
//         />
//       )}
//     </div>
//   );
// }

import React, { useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';

export default function FaceWeb({ setFaceImage, setIsShown, isShown, isReset }) {
  const webcamRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [blinkDetected, setBlinkDetected] = useState(false);
  const [faceDetected, setFaceDetected] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  useEffect(() => {
    const loadModels = async () => {
      try {
        const MODEL_URL = '/models';
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        setModelsLoaded(true);
        console.log('Models loaded successfully!');
      } catch (error) {
        console.error('Error loading models:', error);
      }
    };

    loadModels();
  }, []);

  const calculateEAR = (eye) => {
    const [p1, p2, p3, p4, p5, p6] = eye;
    const vertical1 = Math.sqrt(Math.pow(p2.x - p6.x, 2) + Math.pow(p2.y - p6.y, 2));
    const vertical2 = Math.sqrt(Math.pow(p3.x - p5.x, 2) + Math.pow(p3.y - p5.y, 2));
    const horizontal = Math.sqrt(Math.pow(p1.x - p4.x, 2) + Math.pow(p1.y - p4.y, 2));

    return (vertical1 + vertical2) / (2 * horizontal);
  };

  const detectFaceAndBlink = async () => {
    if (webcamRef.current && modelsLoaded && videoReady && !blinkDetected) {
      const video = webcamRef.current.video;
      const detections = await faceapi
        .detectSingleFace(
          video,
          new faceapi.TinyFaceDetectorOptions({ inputSize: 224, scoreThreshold: 0.4 })
        )
        .withFaceLandmarks();

      if (!detections) {
        setFaceDetected(false);
        setIsButtonEnabled(false);
        return;
      }

      setFaceDetected(true);
      const landmarks = detections.landmarks;
      const leftEye = landmarks.getLeftEye();
      const rightEye = landmarks.getRightEye();

      if (!leftEye || !rightEye) return;

      const leftEAR = calculateEAR(leftEye);
      const rightEAR = calculateEAR(rightEye);
      const avgEAR = (leftEAR + rightEAR) / 2;
      const blinkThreshold = 0.25;

      if (avgEAR < blinkThreshold) {
        setBlinkDetected(true);
      }
    }
  };

  useEffect(() => {
    let animationFrameId;
    const detectWithAnimation = async () => {
      if (!blinkDetected) {
        await detectFaceAndBlink();
        animationFrameId = requestAnimationFrame(detectWithAnimation);
      }
    };

    if (modelsLoaded && videoReady) {
      detectWithAnimation();
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [modelsLoaded, videoReady, blinkDetected]);

  useEffect(() => {
    setIsButtonEnabled(faceDetected && blinkDetected);
  }, [faceDetected, blinkDetected]);

  const handleCapture = () => {
    if (!isButtonEnabled) {
      alert('Capture failed. Please ensure a face is detected and try again.');
      return;
    }

    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setFaceImage(imageSrc);
      setIsShown(true);
      setCapturedImage(imageSrc);
    }
  };

  const handleReset = () => {
    setCapturedImage(null);
    setIsShown(false);
    setBlinkDetected(false);
    setFaceDetected(false);
    setIsButtonEnabled(false);
    setVideoReady(false);
  };

  return (
    <div>
      {!capturedImage ? (
        <div>
          <Webcam
            ref={webcamRef}
            videoConstraints={{ width: 640, height: 480, facingMode: 'user' }}
            onLoadedData={() => setVideoReady(true)}
            onUserMedia={() => console.log('Webcam is ready!')}
            onUserMediaError={(error) => alert(`Webcam access denied: ${error.message}`)}
            screenshotFormat="image/jpeg"
          />
          {modelsLoaded ? (
            <div>
              {faceDetected ? (
                <p style={{ color: 'green', fontWeight: 'bold' }}>Face Detected!</p>
              ) : (
                <p style={{ color: 'red' }}>No Face Detected</p>
              )}
              {!blinkDetected && (
                <p style={{ color: 'blue', fontWeight: 'bold' }}>Please Blink at least once</p>
              )}
              {blinkDetected && (
                <p style={{ color: 'green', fontWeight: 'bold' }}>Blink Detected!</p>
              )}
            </div>
          ) : (
            <p>Loading models...</p>
          )}
          <button
            onClick={handleCapture}
            disabled={!isButtonEnabled}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: isButtonEnabled ? 'blue' : 'gray',
              color: 'white',
              cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
              border: 'none',
              borderRadius: '5px',
            }}
          >
            {isButtonEnabled ? 'Capture Image' : 'Waiting for Face and Blink...'}
          </button>
        </div>
      ) : (
        <div>
          <img
            src={capturedImage}
            alt="Captured Face"
            style={{
              maxWidth: '100%',
              height: 'auto',
              marginTop: '20px',
              borderRadius: '5px',
            }}
          />
          {isReset && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <button
                onClick={handleReset}
                style={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: 'red',
                  color: 'white',
                  cursor: 'pointer',
                  border: 'none',
                  borderRadius: '5px',
                  marginBottom: '20px',
                }}
              >
                Reset Face
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
