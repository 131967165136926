import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import Projects from 'layouts/dashboard/components/Projects';
import OrdersOverview from 'layouts/dashboard/components/OrdersOverview';
import apiService from '../../services/ApiService';

// Data for charts
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import Statuses from 'layouts/statuses';
import { AuthContext } from 'context/AuthContext';

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { user, permission } = useContext(AuthContext);
  const {
    Admin = false,
    HrManager = false,
    HRBP = false,
    ItAdmin = false,
    Employee = false,
  } = permission;

  const [companyCount, setCompanyCount] = useState(0);
  const [businessUnitCount, setBusinessUnitCount] = useState(0);
  const [departmentCount, setDepartmentCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [attendanceStatus, setAttendanceStatus] = useState([]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [companyRes, businessUnitRes, departmentRes, userRes] = await Promise.all([
          apiService.get('/api/v1/companies/list'),
          apiService.get('/api/v1/businessUnits/list'),
          apiService.get('/api/v1/departments/list'),
          apiService.get('/api/v1/users/list'),
        ]);

        setCompanyCount(companyRes.data.companies.length);
        setBusinessUnitCount(businessUnitRes.data.businessUnits.length);
        setDepartmentCount(departmentRes.data.departments.length);
        setUserCount(userRes.data.data.length);
      } catch (error) {
        console.error('Failed to fetch counts:', error);
      }
    };

    fetchCounts();
  }, []);

  //get attendance status count

  useEffect(() => {
    const getAttendanceStatus = async () => {
      try {
        const response = await apiService.get('/api/v1/attendance/statusCount');
        const { attendanceStatusCount } = response?.data?.data;
        setAttendanceStatus(attendanceStatusCount);
      } catch (error) {
        console.error('Failed to fetch attendance status:', error);
      }
    };

    getAttendanceStatus();
  }, []);

  console.log('attendanceStatus', attendanceStatus);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {Employee && !HrManager && !Admin && !HRBP && !ItAdmin ? (
        <MDBox py={3}>
          <h1 style={{ textAlign: 'center', color: '#1A73E8', fontWeight: 'bold' }}>
            Welcome to Babtify!
          </h1>
          <p style={{ textAlign: 'center', color: '#888' }}>We’re glad to have you here.</p>
        </MDBox>
      ) : Employee && (HrManager || HRBP || ItAdmin) ? (
        // Employee with additional roles (HRManager, Admin, HRBP, or ItAdmin)
        <MDBox mt={3}>
          <MDBox py={3}>
            <MDBox>
              <MDBox py={3}>
                <Grid container spacing={3}>
                  {attendanceStatus.map((status, index) => {
                    const getStatusProps = (status) => {
                      switch (status.status.toLowerCase()) {
                        case 'present':
                          return { color: 'success', icon: 'check_circle' };
                        case 'absent':
                          return { color: 'error', icon: 'cancel' };
                        case 'late':
                          return { color: 'warning', icon: 'access_time' };
                        case 'lateandearlycheckout':
                          return { color: 'info', icon: 'event' };
                        case 'presentandearlycheckout':
                          return { color: 'dark', icon: 'hourglass_empty' };
                        default:
                          return { color: 'default', icon: 'help_outline' };
                      }
                    };

                    const { color, icon } = getStatusProps(status);

                    return (
                      <Grid key={index} item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color={color}
                            icon={icon}
                            title={`${status.status} Users`}
                            count={status.count}
                          />
                        </MDBox>
                      </Grid>
                    );
                  })}
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox mt={3}>
            <Statuses />
          </MDBox>
        </MDBox>
      ) : Admin ? (
        <>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="business"
                    title="Companies"
                    count={companyCount}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+55%",
                    //   label: "than last week",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="apartment"
                    title="Business Units"
                    count={businessUnitCount}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="account_tree"
                    title="Departments"
                    count={departmentCount}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="people"
                    title="Total Users"
                    count={userCount}
                    percentage={{
                      color: 'success',
                      amount: '',
                      label: 'Just updated',
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={3}>
            <Statuses />
          </MDBox>
        </>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
