import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import apiService from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { FormHelperText, InputLabel } from '@mui/material';
import toast from 'react-hot-toast';

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    personNumber: '',
    role: 'Employee',
    departmentId: '',
  });
  const [errors, setErrors] = useState({});
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
      // Clear errors for the field as the user types
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };


  const validate = () => {
    const newErrors = {};
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!formData.fullName.trim()) newErrors.fullName = 'Full Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.password.trim()) newErrors.password = 'Password is required';
    else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        'Password must be at least 8 characters, include an uppercase letter, a number, and a special character';
    }
    if (!formData.personNumber.trim()) newErrors.personNumber = 'Person Number is required';
    if (!formData.departmentId) newErrors.departmentId = 'Department is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      const response = await apiService.post('/api/v1/users/create', formData);
      if (response.status === 201) {
        console.log('User created successfully:', response.data);
        toast.success(`User created successfully`);
        setLoading(false);
        navigate('/users');
      }
    } catch (error) {
      console.error('Failed to create user:', error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getShifts = async () => {
    try {
      const response = await apiService.get('/api/v1/departments/list');

      if (response.status === 200) {
        setShifts(response.data.departments);
      }
    } catch (error) {
      toast.error('Failed to fetch shifts');
      console.error('Failed to fetch shifts:', error);
    }
  };

  useEffect(() => {
    getShifts();
  }, []);

  console.log('shifts', shifts);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Create User
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Full Name"
                    name="fullName"
                    variant="standard"
                    fullWidth
                    value={formData.fullName}
                    onChange={handleInputChange}
                    error={!!errors.fullName}
                  />
                  {errors.fullName && <FormHelperText error>{errors.fullName}</FormHelperText>}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}

                  />
                  {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Password"
                    name="password"
                    variant="standard"
                    fullWidth
                    value={formData.password}
                    onChange={handleInputChange}
                    error={!!errors.password}
                  />
                  {errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Person Number"
                    name="personNumber"
                    variant="standard"
                    fullWidth
                    value={formData.personNumber}
                    onChange={handleInputChange}
                    error={!!errors.personNumber}
                  />
                  {errors.personNumber && <FormHelperText error>{errors.personNumber}</FormHelperText>}
                </MDBox>

                <MDBox mb={2}>
                  <InputLabel id="shift-id-label">Select Department *</InputLabel>
                  <Select
                    labelId="shift-id-label"
                    name="departmentId"
                    value={formData.departmentId}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                    error={!!errors.departmentId}
                  >
                    {shifts.map((shift) => (
                      <MenuItem key={shift.name} value={shift._id}>
                        {shift.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.departmentId && <FormHelperText error>{errors.departmentId}</FormHelperText>}
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    {loading ? 'Creating...' : 'Create'}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Create;
