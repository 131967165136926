import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import moment from 'moment-timezone'; // Make sure this line is present
import TableCustomized from 'components/TableCustomized';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import MDButton from 'components/MDButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import TableCustomizedUpdate from 'components/TableCustomizedUpdated';
import AdminCustomized from 'components/AdminCustomized';
import StatusRecord from 'components/StatusRecord';

const timezone = 'Asia/Karachi'; // Set your desired timezone

const dataRange = [
  {
    id: 1,
    name: 'Today',
    value: '1day',
  },
  {
    id: 2,
    name: '7 days',
    value: '1week',
  },
  {
    id: 3,
    name: '1 month',
    value: '1month',
  },
];

function AbsentAttendance() {
  const { user } = useContext(AuthContext);

  const [dateData, setDateData] = useState({
    startDate: dayjs(), // Initialize with the current date
    endDate: dayjs(), // Initialize with the current date
  });
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [timeRange, setTimeRange] = useState('');

  console.log('departmentId', departmentId);
  const handleChange = () => {
    setDepartmentId(event.target.value);
  };

  const handleDateChange = (field) => (newValue) => {
    const updatedDate = dayjs(newValue);

    setDateData((prevState) => {
      if (field === 'startDate' && updatedDate.isAfter(prevState.endDate, 'day')) {
        toast.error('Start Date cannot be after End Date');
        return prevState;
      }

      if (field === 'endDate' && updatedDate.isBefore(prevState.startDate, 'day')) {
        toast.error('End Date cannot be before Start Date');
        return prevState;
      }

      return {
        ...prevState,
        [field]: updatedDate,
      };
    });
  };

  const [attendanceLogs, setAttendanceLogs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  console.log('attendanceLogs', attendanceLogs);

  const getDepartments = async () => {
    try {
      const response = await apiService.get('/api/v1/departments/list');
      setDepartments(response?.data?.departments);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleGetData = async () => {
    setLoading(true);

    try {
      let dataToSend = {
        startDate: dayjs(dateData.startDate).format(),
        endDate: dayjs(dateData.endDate).format(),
        status: 'absent',
      };
      if (departmentId) {
        dataToSend.departmentId = departmentId;
      }
      const response = await apiService.post('/api/v1/attendance/fetchUserByStatus', dataToSend);
      if (response.data.success) {
        setAttendanceLogs(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
      setLoading(false);
      toast.error(`${error.response.data.message}`);
    }
  };
  const isFormValid = dateData.startDate && dateData.endDate;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Attendance Logs
                </MDTypography>
              </MDBox>

              <MDBox pt={3} px={3}>
                {isSuccess ? (
                  <MDBox>
                    <MDTypography variant="h6" color="success">
                      {message}
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDTypography variant="h6" color="error">
                    {message}
                  </MDTypography>
                )}

                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={3}>
                    <FormControl sx={{ minWidth: 660 }}>
                      <InputLabel id="demo-simple-select-helper-label">Select Range</InputLabel>
                      <Select
                        sx={{ padding: '10px' }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={timeRange}
                        fullWidth
                        label="Select TimeRange"
                        onChange={(e) => setTimeRange(e.target.value)} 
                      >
                        {dataRange.map((department) => (
                          <MenuItem key={department.id} value={department.value}>
                            {department.name} 
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={3} my={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dateData.startDate}
                        maxDate={dayjs()}
                        onChange={handleDateChange('startDate')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={3} my={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dateData.endDate}
                        maxDate={dayjs()}
                        onChange={handleDateChange('endDate')}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* <Grid item xs={12} md={3} my={3}>
                    <FormControl sx={{ minWidth: 160 }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Select Department
                      </InputLabel>
                      <Select
                        sx={{ padding: '10px' }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={departmentId}
                        fullWidth
                        label="Select Department"
                        onChange={(e) => setDepartmentId(e.target.value)}
                      >
                        {departments.map((department) => (
                          <MenuItem key={department._id} value={department._id}>
                            {department.name} 
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={3} my={3}>
                    <Autocomplete
                      options={departments}
                      getOptionLabel={(option) => option.name} // Display department name
                      onChange={(event, newValue) => setDepartmentId(newValue?._id || '')} // Update departmentId
                      renderInput={(params) => (
                        <TextField {...params} label="Select Department" variant="outlined" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} my={3}>
                    <Button
                      disabled={!isFormValid}
                      style={{
                        backgroundColor: '#0e76ff',
                        color: 'white',
                      }}
                      onClick={() => handleGetData()}
                      // disabled={!attendanceStatus.checkIn || attendanceStatus.checkOut || loading}
                      fullWidth
                    >
                      {loading ? (
                        <CircularProgress color="white" size={'25px'} />
                      ) : (
                        'Get Absent Record'
                      )}
                    </Button>
                  </Grid>
                </Grid>

                {attendanceLogs?.length === 0 || attendanceLogs === undefined ? (
                  <MDTypography
                    sx={{
                      m: 10,
                    }}
                    variant="h6"
                    color="secondary"
                  >
                    No Record found
                  </MDTypography>
                ) : (
                  <MDBox mt={3}>
                    <StatusRecord attendanceLogs={attendanceLogs} />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AbsentAttendance;
